<template>
  <div>
    <div id="secondLevel-attention">
      <vs-checkbox v-model="secondLevel">{{
        lang.conditions.secondLevel[languageSelected]
        }}</vs-checkbox>
      <KonaHelpTooltip
              :text="lang.helpTooltips.botMaker.derivateAgent[languageSelected]"
              position="top"
      />
    </div>
    <div v-show="secondLevel" id="show-after-business-hours">
      <vs-checkbox v-model="outsideBusinessHours">{{
        lang.conditions.replyOutsideBusinessHours[languageSelected]
        }}</vs-checkbox>
      <KonaHelpTooltip
              :text="lang.helpTooltips.botMaker.replyOutsideBusinessHours[languageSelected]"
              position="top"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SecondLevelAttention',
  data() {
    return {
      checkbox: false
    }
  },
  components: {
    KonaHelpTooltip: () => import('@/components/KonaHelpTooltip.vue')
  },
  watch: {
    checkbox(newValue) {
      this.$emit('update:value', newValue)
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'secondLevelAttention',
      'secondLevelAttentionHasChanged',
      'replyOutsideBusinessHours'
    ]),
    secondLevel: {
      get() {
        return this.secondLevelAttention
      },
      set(val) {
        this.SET_SECOND_LEVEL_ATTENTION(val)
        this.SET_SECOND_LEVEL_ATTENTION_HAS_CHANGED(true)
      }
    },
    outsideBusinessHours: {
      get() {
        return this.replyOutsideBusinessHours
      },
      set(val) {
        this.SET_REPLY_OUTSIDE_BUSINESS_HOURS(val)
      }
    }
  },
  methods: {
    ...mapMutations('botMaker', [
      'SET_SECOND_LEVEL_ATTENTION',
      'SET_SECOND_LEVEL_ATTENTION_HAS_CHANGED',
      'SET_REPLY_OUTSIDE_BUSINESS_HOURS'
    ])
  }
}
</script>

<style lang="scss">
#secondLevel-attention, #show-after-business-hours {
  margin: 15px 0;
  margin-bottom: 0;
  display: flex;
  #kona-help-tooltip {
    position: relative;
    right: 0;
    top: 0;
  }
}
</style>
